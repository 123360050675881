.layout {
  padding-bottom: 24px;
}

.fileInput {
  display: flex;
  flex-wrap: wrap;
  grid-area: input;
}

.fileInputPictures {
  composes: fileInput;
  background: var(--input-background);
  border-radius: 20px;
  padding: 12px;
  justify-content: space-evenly;
}

.files {
  margin-top: 12px;
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 6px;
  grid-template-columns: auto 1fr auto auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    'input-title preview-title category-title val-title'
    'input       preview       category       val';
}

.multipleFiles {
  composes: files;
  grid-template-columns: 1fr auto auto;
  grid-template-areas:
    'input-title category-title val-title'
    'input       category       val';
}

.avatar {
  width: 100px;
  height: 100px;
  background: #f0f0f0;
  border-radius: 50px;
  margin: 12px;
}

.categoryTitle {
  grid-area: category-title;
}

.categoryCard {
  grid-area: category;
  background: var(--input-background);
  border-radius: 20px;
  padding: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.avatarBlue {
  composes: avatar;
  background: var(--purple);
}

.avatarRed {
  composes: avatar;
  background: var(--pink);
}

.spacer {
  flex: 1;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: val;
  align-self: center;
}

.previewCard {
  grid-area: preview;
  background: var(--input-background);
  padding: 24px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.previewTitle {
  grid-area: preview-title;
}

.inputTitle {
  grid-area: input-title;
}

.ctaButton {
  width: 100%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
}
