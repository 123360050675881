.layout {
  overflow: auto;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 24px;
}

.body {
  grid-row: 1 / 3;
  grid-column: 2 / 3;
}

.choosableLayout {
  composes: body;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, auto) 1fr;
  grid-auto-rows: auto;
  grid-template-areas:
    'edit         edit         edit         edit         edit         edit'
    'id           id           id           title        title        title'
    'metadata     metadata     metadata     metadata     metadata     metadata'
    'translations translations translations translations translations translations';
  grid-gap: 24px;
}

.choosableButtons {
  display: flex;
  grid-area: edit;
  justify-self: end;
}

.translationSubHeader {
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: 24px;
}
