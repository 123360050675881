.root {
  min-height: 100vh;
  display: grid;
  background: var(--background-color);
  grid-template-areas:
    'sidebar navbar'
    'sidebar main';
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  transition: filter 0.2s;
}

@media (max-width: 450px) {
  .root {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      'sidebar'
      'navbar'
      'main';
  }
}
