.grid {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 24px;
  padding-bottom: 24px;
}

.imgClassName {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 300px;
  border: 2px solid var(--purple);
}

.profileRow {
  display: flex;
}

.paramTitle {
  padding-right: 6px;
}

.paramContent {
  font-weight: 500;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.paramWrapper {
  padding: 3px 0;
  display: flex;
  align-items: baseline;
  overflow: hidden;
}

.descriptor {
  font-size: 1.5rem;
  font-weight: bold;
  padding-bottom: 6px;
}

.infoRow {
  flex: 1;
  overflow: hidden;
}

.cardProfiles {
  background: var(--input-background);
  border-radius: 20px;
  padding: 12px;
}

.picturesWrap {
  display: flex;
  overflow: auto;
  margin: -12px;
  padding: 12px 6px;
}

.carouselPicture {
  padding: 6px;
}

.id {
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: normal;
  color: #777;
  padding: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
}
