.navbar {
  display: flex;
  padding: 24px;
  position: sticky;
  top: 0px;
  background: var(--dark-background-color);
  grid-area: navbar;
  z-index: 10000;
}

.search {
  border-radius: 10px;
  border: none;
  background: var(--input-background);
  flex: 1;
  padding: 12px 18px;
  color: var(--input-color);
  outline: none;
  font-weight: 500;
  font-size: 1rem;
  transition: all 0.2s;
}

.search[disabled] {
  background: var(--input-background-disabled);
  cursor: not-allowed;
}

.search::placeholder {
  color: var(--input-placeholder-color);
}

.search:focus {
  box-shadow: 0px 0px 5px 1px var(--box-shadow-color);
}
