.sureModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(0px);
  opacity: 0;
  z-index: 100000000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.realModal {
  padding: 24px;
  border-radius: 20px;
}

.cardBody {
  background: var(--input-background);
  margin: 24px -24px -24px -24px;
  padding: 24px;
  padding-top: 24px;
  max-width: 400px;
  white-space: normal;
  border-radius: 0 0 20px 20px;
  color: var(--text-grey);
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}
