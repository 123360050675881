.glassmorphism {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.02);
  backdrop-filter: blur(60px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.nameWrapper {
  composes: glassmorphism;
  display: flex;
  position: absolute;
  flex-direction: column;
  border-radius: 10px 10px 0 0;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0px 24px;
  padding-top: 24px;
  z-index: 1000;
}

@media (prefers-color-scheme: dark) {
  .glassmorphism {
    background: rgba(0, 0, 0, 0.25);
    box-shadow: 0 8px 32px 0 rgba(38, 43, 106, 0.37);
    border: 1px solid rgba(0, 0, 0, 0.18);
  }
}

.namesWrapper {
  display: flex;
  overflow: auto;
  margin: 0 -24px;
  padding: 0 24px;
}

.namePill {
  padding: 12px 24px;
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 24px 12px;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.namePill:first-child {
  margin-left: 0;
}

.messagesWrapper {
  flex: 1;
  overflow: auto;
  /* min-height: 600px; */
}

.messageWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.coloredMessage {
  margin: 1px 24px;
  border-radius: 20px;
  font-weight: 500;
  color: white;
  padding: 12px 24px;
  position: relative;
}

.hideImage {
  width: 20px;
}

.trashImage {
  width: 16px;
}

.transparentButton {
  appearance: none;
  border: none;
  background: inherit;
  color: inherit;
  font-family: inherit;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.actionIcons {
  display: flex;
  opacity: 0;
  background: var(--background-color);
  padding: 9px 18px;
  border-radius: 200px;
  align-items: center;
}

.messageWrapper:hover .actionIcons {
  opacity: 1;
}

.left {
  justify-content: flex-end;
}

.right {
  justify-content: flex-start;
}

.labelInput {
  max-width: 100%;
  width: 100%;
}

.picture {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  margin-right: 12px;
}

.pictureImg {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  object-fit: cover;
}

.buttonName {
  display: flex;
}

.buttonName > * {
  margin: 12px;
}

.buttonName > *:first-child {
  margin-left: 0;
}

.header,
.idTitle {
  composes: cardTitle from '../../../components/card/card.module.css';
}

.layout {
  display: grid;
  grid-template-areas: 'body input';
  grid-template-columns: 1fr auto;
  grid-gap: 24px;
  height: 100%;
  padding-bottom: 24px;
}

.mainCard {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.bodyCard {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: -24px;
}

.loader {
  grid-column: 1 / 3;
}

.deleteImage {
  width: 10px;
}

.placeholderImages {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 4px 9px;
  border-radius: 200px;
  z-index: 100;
  bottom: -6px;
  right: -6px;
}

.topPadding {
  padding-top: 190px;
}

@media (max-width: 450px) {
  .layout {
    grid-template-areas:
      'body'
      'input';
    grid-template-columns: 1fr;
    height: auto !important;
  }

  .namesWrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .namePill {
    margin: 6px 0;
  }

  .namePill:last-child {
    margin-bottom: 12px;
  }

  .topPadding {
    padding-top: 220px;
  }

  .smallHidden {
    display: none;
  }

  .actionIcons {
    display: none;
  }
}
