.cardTitle {
  font-size: 1.1rem;
  margin: 12px 0;
  margin-top: 24px;
  color: var(--text-grey);
  background: var(--input-background);
  padding: 12px;
  border-radius: 20px;
  text-align: center;
  font-weight: 500;
}

.wrapper {
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  color: var(--white);
}

.wrapper:last-child {
  margin-bottom: 0;
}

.left {
  composes: wrapper;
  align-items: flex-start;
}

.right {
  composes: wrapper;
  align-items: flex-end;
}

.messageContent {
  width: 80%;
  padding: 12px 18px;
  border-radius: 20px;
  white-space: pre-line;
}

.left .messageContent {
  background: var(--pink);
}

.right .messageContent {
  background: var(--purple);
}

.messageDate {
  margin-top: 6px;
  color: #777;
}

.seeMore {
  align-self: flex-end;
  margin-top: 12px;
}

.spacer {
  flex: 1;
}

.metadataFlex {
  background: var(--input-background);
  border-radius: 20px;
  padding: 12px 18px;
  display: flex;
  align-items: baseline;
}

.metadataTitle {
  font-weight: 400;
  color: var(--title-color);
  margin-right: 6px;
  font-size: 1.1rem;
}

.metadataData {
  color: var(--text-grey);
  font-weight: 400;
  font-size: 0.9rem;
}
