.defaultButton {
  padding: 12px 24px;
  border-radius: 20px;
  color: var(--white);
  font-weight: 600;
  font-size: 1rem;
  border: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-align: center;
  justify-content: center;
  transition: background 0.2s;
  transition: color 0.2s;
}

.primaryButton {
  composes: defaultButton;
  background: var(--pink);
}

.secondaryButton {
  composes: defaultButton;
  background: var(--purple);
}

.warningButton {
  composes: defaultButton;
  background: var(--orange);
}

.shutdown,
.defaultButton[disabled] {
  background: var(--input-background-disabled);
  cursor: not-allowed;
  color: var(--input-placeholder-color);
}

.shutdown {
  cursor: pointer;
}

.infoButton {
  composes: defaultButton;
  background: var(--green);
}

.icon {
  margin-left: 12px;
  width: 15px;
}
