.conversationsGrid {
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr;
}

.cardTitle {
  padding: 18px;
  margin: -24px;
  margin-bottom: 0;
  border-radius: 20px 20px 0px 0px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
}

.id {
  margin: 6px;
  font-weight: 600;
}

.bodyCard {
  padding-top: 24px;
}

.bodyGrid {
  display: grid;
  grid-gap: 6px;
  grid-template-columns: repeat(2, 1fr);
  background: var(--input-background);
  padding: 12px;
  border-radius: 20px;
  align-items: center;
}

.bodyTitle {
  grid-column: 1 / 3;
  font-weight: 600;
  font-size: 1.2rem;
  padding-bottom: 8px;
}

.img {
  width: 100%;
  height: 100%;
  display: block;
}

.item {
  width: 20px;
  height: 20px;
  padding: 6px;
  border-radius: 30px;
  align-self: center;
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.crossItem {
  composes: item;
  background: rgba(255, 0, 0, 0.3);
}

.checkItem {
  composes: item;
  background: rgba(0, 255, 0, 0.3);
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
}

.blocked {
  display: flex;
  padding-bottom: 3px;
  background: var(--input-background);
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 6px;
  padding-top: 3px;
  overflow: hidden;
}

.blocked:first-child {
  border-radius: 20px 20px 0 0;
}

.blockedId {
  padding-right: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blocked:last-child {
  padding-bottom: 0px;
  border-radius: 0 0 20px 20px;
  padding-bottom: 3px;
}

.lastMessageWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.lastMessage {
  width: 100%;
  border-radius: 16px 16px 0 16px;
  padding: 6px 12px;
  color: var(--white);
}

.lastMessageAuthor {
  display: flex;
  justify-content: flex-end;
  background: var(--input-background);
  padding: 6px 12px;
  border-radius: 16px 0 16px 16px;
  /* color: var(--title-color); */
}

@media (max-width: 450px) {
  .lastMessageAuthor {
    background: var(--card-background);
  }
}
