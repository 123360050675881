.layout {
  display: grid;
  grid-template-areas:
    'duels duels sexes'
    'archived on-boarding on-boarding';
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  padding-bottom: 24px;
}

@media (max-width: 1000px) {
  .layout {
    grid-template-areas:
      'duels duels duels'
      'sexes sexes sexes'
      'archived archived archived'
      'on-boarding on-boarding on-boarding';
  }
}
