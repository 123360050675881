.main {
  flex: 1;
  display: flex;
}

.mainSection {
  display: flex;
  flex-direction: column;
  grid-area: main;
}

.title {
  padding: 0px 24px;
  padding-top: 12px;
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0;
  color: var(--title-color);
  background: var(--dark-background-color);
  position: sticky;
  z-index: 10000;
}

.subTitle {
  padding: 3px 24px 12px 24px;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  color: var(--text-grey);
  background: var(--dark-background-color);
  position: sticky;
  z-index: 10000;
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.bodyWrapper {
  padding: 24px;
  padding-bottom: 0px;
  flex: 1;
  max-height: 100%;
}

.notFoundWrapper {
  display: flex;
  height: 100%;
  padding-bottom: 24;
  align-items: center;
  justify-content: center;
}

.notFoundAlign {
  text-align: center;
}

.four0Four {
  font-size: 20rem;
  font-weight: 600;
}

.notFoundText {
  font-size: 5rem;
}
