.layout {
  display: grid;
  grid-template-areas:
    'max-duels max-skip'
    'other empty';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 24px;
  height: 100%;
  padding-bottom: 24px;
}

.buttons {
  display: flex;
}

.loader {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}

@media (max-width: 450px) {
  .layout {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      'max-duels'
      'max-skip';
  }
}
