code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --white: rgb(255, 255, 255);
  --pink: rgb(245, 0, 116);
  --dark-pink: rgb(196, 0, 92);
  --purple: rgb(130, 80, 254);
  --orange: rgb(255, 153, 96);
  --green: rgb(45, 214, 129);
  --text-grey: rgb(68, 68, 68);
  --background-color: var(--white);
  --dark-background-color: rgb(250, 250, 250);
  --box-shadow-color: rgb(221, 221, 221);
  --card-background: rgb(243, 245, 249);
  --middle-card-background: rgb(232, 236, 247);
  --dark-card-background: rgb(182, 194, 217);
  --input-background: rgb(255, 255, 255);
  --input-background-disabled: rgb(220, 220, 220);
  --input-color: rgb(119, 119, 119);
  --input-placeholder-color: rgb(136, 136, 136);
  --title-color: rgb(20, 20, 20);
  --default-color: #555;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color: rgb(22, 22, 22);
    --dark-background-color: rgb(25, 25, 25);
    --text-grey: rgb(238, 238, 238);
    --box-shadow-color: rgb(55, 55, 55);
    --card-background: rgb(40, 40, 40);
    --middle-card-background: rgb(45, 45, 45);
    --dark-card-background: rgb(50, 50, 50);
    --input-background: rgb(60, 60, 60);
    --input-background-disabled: rgb(0, 0, 0);
    --title-color: var(--white);
    --default-color: #ccc;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--default-color);
  background: var(--background-color);
}

* {
  box-sizing: border-box;
}

.firebase-emulator-warning {
  display: none;
}
