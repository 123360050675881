.layout {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
}

.padded {
  /* margin: 12px 0; */
  border-radius: 0;
  border-bottom: 1px solid var(--middle-card-background);
}

.padded:hover {
  background: var(--dark-background-color);
}

.padded:last-child {
  border-radius: 0 0 20px 20px;
  border-bottom: none;
}

.padded:nth-child(2) {
  border-radius: 20px 20px 0 0;
}

.padded:nth-child(2):last-child {
  border-radius: 20px;
}

.margedLeft {
  margin-left: 12px;
  margin-top: 12px;
}

.paddedBottom {
  margin-bottom: 12px;
}

.inputForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mainForm {
  composes: inputForm;
}

.buttonsForm {
  composes: inputForm;
}

.optionsButtons {
  display: flex;
}

.adminPill {
  display: grid;
  grid-template-areas: 'picture info delete';
  grid-template-columns: auto 1fr auto auto;
  grid-gap: 12px;
  align-items: center;
}

.adminPill:first-child {
  margin-top: 0;
}

.nameUid {
  display: flex;
  align-items: baseline;
}

.nameStatus {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.name {
  color: var(--title-color);
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 6px;
  margin-right: 6px;
}

.claimEmail {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.email {
  color: var(--text-grey);
}

.claim {
  padding: 6px 6px;
  margin: 0 6px 6px 0;
  font-size: 1rem;
  border-radius: 10px;
  color: white;
  font-weight: 600;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border: none;
}

.adminClaim {
  composes: claim;
  grid-area: claim;
  background: var(--purple);
}

.modoClaim {
  composes: claim;
  grid-area: claim;
  background: var(--green);
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.centerButton {
  grid-area: delete;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profilePicture {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  margin-right: 12px;
}

.uid {
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 450px) {
  .mainForm {
    flex-direction: column;
    align-items: stretch;
  }

  .buttonsForm {
    flex-direction: column;
    align-items: flex-end;
  }

  .nameUid {
    flex-direction: column;
  }

  .adminPill {
    grid-template-areas:
      'picture info info'
      'empty empty delete';
  }
}
