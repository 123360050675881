.layout {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 24px;
}

.duelWrapper {
  padding: 24px 0;
}

.duelWrapper:first-child {
  padding-top: 0;
}

.title {
  padding-bottom: 12px;
  font-weight: 600;
  font-size: 1.3rem;
}

.leftButton {
  display: flex;
  justify-content: flex-end;
}
