.label {
  display: block;
  max-width: 300px;
}

.labelName {
  margin-bottom: 6px;
  margin-top: 12px;
}

.input {
  background: var(--input-background);
  padding: 12px 18px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: var(--input-color);
  font-weight: 500;
  font-size: 1rem;
  transition: all 0.2s;
  width: 100%;
  resize: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

.input::placeholder {
  color: var(--input-background-disabled);
}

.input[disabled] {
  background: var(--input-background-disabled);
  color: var(--input-color);
  opacity: 1;
}

.input[disabled]::placeholder {
  color: var(--input-color);
}

.inputFile {
  display: none;
}

.labelInput {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--input-background);
  border-radius: 20px;
  padding: 24px;
  cursor: pointer;
  width: 100%;
}

.inputImg {
  margin-bottom: 24px;
  height: 100px;
  width: 100px;
}
