.categoryName {
  text-transform: capitalize;
  margin-bottom: 24px;
}

.mainTitle {
  color: #777;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  transition: color 0.2s;
  cursor: pointer;
  position: sticky;
  top: 0;
}

.paddedTitle {
  composes: mainTitle;
  padding-right: 24px;
  background: var(--background-color);
  z-index: 1000;
}

.categoryName:first-child {
  margin-top: 0;
}

.block {
  width: 4px;
  height: 20px;
  border-radius: 90px;
  margin-right: 12px;
  transition: background 0.2s;
}

.redBlock {
  composes: block;
  background: var(--pink);
}

.selectedTitle {
  color: var(--text-grey);
  composes: mainTitle;
  font-weight: 700;
  background: var(--background-color);
  z-index: 1000;
}

.questionsSection {
  padding: 0 24px;
}

.mainQuestion {
  composes: mainTitle;
  margin: 12px 0;
  color: #bbb;
  width: 200px;
  position: relative;
}

.questionName {
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: none;
}

.selectedQuestion {
  composes: mainQuestion;
  color: var(--text-grey);
}

.selector {
  overflow: auto;
  height: 100%;
}

@media (prefers-color-scheme: dark) {
  .mainTitle {
    color: #bbb;
  }

  .mainQuestion {
    color: #777;
  }

  .selectedTitle {
    color: var(--white);
  }

  .selectedQuestion {
    color: var(--white);
  }
}
