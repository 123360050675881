.layout {
  overflow: hidden;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    'header'
    'main';
}

.body {
  grid-area: main;
  white-space: pre;
  display: grid;
  grid-auto-rows: auto;
  align-content: flex-start;
  overflow: auto;
}

.infoWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr) repeat(3, 150px);
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.cardLinkWrapper {
  text-decoration: none;
  color: inherit;
  display: block;
  overflow: hidden;
}

.headerWrapper {
  /* background: var(--dark-card-background); */
  /* color: var(--white); */
  font-weight: 600;
  font-size: 1.2rem;
  border-radius: 20px 20px 0 0;
  border-bottom: 1px solid var(--dark-card-background);
}

.header {
  composes: infoWrapper;
}

.name,
.disWrap,
.uid,
.email {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 12px;
  max-width: 100%;
  line-height: 25px;
  text-decoration: none;
  color: inherit;
}

.uid {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.disWrap {
  display: flex;
  align-items: center;
}

.enabled {
  font-weight: 600;
  color: var(--white);
  padding: 12px 18px;
  border-radius: 10px;
  opacity: 0.6;
  background: var(--green);
}

.disabled {
  composes: enabled;
  background: var(--orange);
}

.button,
.loadMore {
  margin-bottom: 24px;
}

.spacer {
  padding: 12px;
}

.noRound {
  font-weight: 500;
  border-radius: 0px;
  padding: 12px;
}

.noRound:nth-last-child(2) {
  border-radius: 0 0 20px 20px;
}

.noRound:nth-child(2n + 1) {
  background: var(--middle-card-background);
}

.noRound:hover {
  background: var(--dark-background-color);
}

.loadMore {
  grid-area: load;
  margin-left: 24px;
}

.fakeUser {
  grid-area: empty;
  justify-self: flex-end;
  align-self: flex-start;
}

.cardBody {
  display: flex;
  flex-wrap: wrap;
  margin: -18px;
}

.cardBody > * {
  /* flex-shrink: 0; */
  flex-grow: 1;
  overflow: hidden;
}

.cardSectionWrapper {
  display: flex;
  padding: 6px;
}

.cardSection {
  padding: 12px;
  background: var(--input-background);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.cardField {
  font-weight: bold;
  font-size: 1.1rem;
  width: 100%;
}

.cardAction {
  padding-top: 3px;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
}

@media (max-width: 450px) {
  .layout {
    overflow: unset;
  }
  .body {
    overflow: unset;
  }
}
