.layout {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 24px;
}

.buttonsRow {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

@media (max-width: 450px) {
  .buttonsRow {
    flex-direction: column;
  }
}
