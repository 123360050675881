.loginButton {
  display: flex;
  align-items: center;
  color: var(--white);
  background: #5185ec;
  box-shadow: 0 0 2px 0 #999;
  appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
}

.loginText {
  padding: 0 12px;
  color: var(--white);
  font-weight: 600;
  font-size: 0.9rem;
  white-space: nowrap;
}
