.sidebar {
  background: var(--pink);
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
  box-shadow: 3px 0px 5px 0px var(--box-shadow-color);
  align-items: center;
  position: sticky;
  top: 0px;
  height: 100vh;
  z-index: 1000000000;
  grid-area: sidebar;
  overflow: auto;
}

.link,
.buttonMenu {
  padding: 18px 0px;
  margin: 0 12px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 12px;
  color: var(--white);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transition: background 0.2s;
}

.buttonMenu {
  display: none;
}

.link:hover {
  background: var(--dark-pink);
}

.logo {
  max-width: 60px;
  margin-top: 12px;
  margin-bottom: 30px;
}

.linkIcon {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
}

.link:global(.active) {
  background: var(--dark-pink);
}

.flex {
  flex: 1;
}

.drawerWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  z-index: 10000;
  transition: left 0.2s;
}

.drawerWrapperOpened {
  composes: drawerWrapper;
  left: 0;
  right: 0;
}

.drawerClicker {
  flex: 1;
}

.drawerWrapperClosed {
  composes: drawerWrapper;
  left: -100vw;
}

.drawer {
  width: 250px;
  background: var(--pink);
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.4);
}

.drawerSticky {
  position: sticky;
  top: 0;
  overflow: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

@media (max-width: 450px) {
  .sidebar {
    flex-direction: row;
    height: auto;
    padding: 12px 12px;
  }

  .sidebar > a,
  .sidebar > button {
    display: none;
  }

  .logo {
    /* max-width: 30px; */
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 12px;
  }

  .sidebar > button.buttonMenu {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .linkIcon {
    margin-bottom: 0;
  }

  .link {
    margin: 0;
  }
}
