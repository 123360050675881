.layout {
  height: 100%;
  display: grid;
  grid-gap: 24px;
  grid-template-areas:
    'courted courted        courted'
    'duel    first-profile  action'
    'duel    second-profile action';
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr 1fr;
  padding-bottom: 24px;
}

.loaderCard {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1 / 4;
  grid-row: 1 / 4;
}

.loaderProfile {
  margin: auto;
}

.duelCard {
  grid-area: duel;
}

.profileGrid {
  padding-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
}

.profileRow {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
}

.profileItem {
  color: var(--text-grey);
  font-size: 1rem;
}

.profilePictureWrapper {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileWrapperPurple {
  composes: profilePictureWrapper;
  background: var(--purple);
}

.profileWrapperPink {
  composes: profilePictureWrapper;
  background: var(--pink);
}

.profilePicture {
  width: 54px;
  height: 54px;
  border-radius: 30px;
  object-fit: cover;
  display: block;
}

.awaitingOpponent {
  font-size: 1.2rem;
  font-weight: bold;
}

.competitors {
  display: flex;
  flex-wrap: wrap;
}

.competitorsButton {
  margin: 0 12px;
}

.competitorsButton:first-child {
  margin-left: 0;
}

.competitorsButton:last-child {
  margin-right: 0;
}

.left {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.right {
  composes: left;
  justify-content: flex-start;
}

.winnerPillWrapper {
  display: flex;
}

.winnerPill {
  composes: warningButton from '../../../components/button/button.module.css';
  cursor: default;
  margin-top: 24px;
}

.archivePill {
  composes: infoButton from '../../../components/button/button.module.css';
  cursor: default;
  margin-top: 24px;
}

.title {
  composes: cardTitle from '../../../components/card/card.module.css';
}

.loaderCenter {
  display: flex;
  justify-content: center;
  padding: 24px;
}

@media (max-width: 450px) {
  .layout {
    grid-template-columns: 1fr;
    grid-template-areas:
      'courted'
      'first-profile'
      'second-profile'
      'duel'
      'action';
  }

  .profileRow {
    padding-top: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
  }
}
