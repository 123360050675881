.card {
  background: var(--card-background);
  padding: 24px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.cardOverflow {
  composes: card;
  overflow: hidden;
}

.card:first-child {
  margin-top: 0;
}

.hidden {
  overflow: hidden;
}

.centerChild {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardTitle {
  color: var(--title-color);
  text-transform: capitalize;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cardNoCapitalize {
  composes: cardTitle;
  text-transform: none;
}

.cardSubTitle {
  color: #777;
}

.cardSubTitle code {
  background: var(--input-color);
  color: var(--white);
  padding: 0 6px;
  border-radius: 6px;
}

.row {
  display: flex;
  justify-content: space-between;
}
