.likeGrid {
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'buttons profile'
    'messages profile'
    'messages empty';
  grid-gap: 24px;
  height: 100%;
}

.buttonIcon {
  grid-area: 'buttons';
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image {
  width: 300px;
  height: 300px;
  border-radius: 100px;
  object-fit: cover;
}

.name {
  font-size: 3rem;
  font-weight: bold;
  padding-bottom: 12px;
}

.infos {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}

.courtMessage {
  display: flex;
  flex-direction: column;
}

.courtMessageName {
  padding-bottom: 4px;
  font-weight: 600;
  font-size: 0.9rem;
}

.courtMessageContent {
  padding: 12px 18px;
  border-radius: 14px;
  color: var(--white);
  font-weight: 600;
  font-size: 1rem;
}

.courtMessageWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.courtMessage {
  flex: 1;
  align-items: flex-start;
  padding: 4px;
}

.courtMessageWrapper:nth-child(2n) .courtMessageContent {
  background: var(--pink);
}

.courtMessageWrapper:nth-child(2n + 1) .courtMessageContent {
  background: var(--purple);
}

.checkbox {
  position: fixed;
  top: -100000px;
  left: -100000px;
  width: 1px;
  height: 1px;
}

.checkboxStyle {
  width: 50px;
  height: 50px;
  border: 4px solid var(--input-background);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activeCheckbox {
  composes: checkboxStyle;
  background: var(--pink);
}

.inactiveCheckbox {
  composes: checkboxStyle;
  background: var(--background);
}

.checkmark {
  width: 30px;
  height: 30px;
}

.profileItem {
  color: var(--text-grey);
  font-size: 1rem;
  font-weight: 500;
  padding: 3px 0;
}

.questionMessage {
  font-size: 1.2rem;
  font-weight: 600;
  padding-bottom: 6px;
}

.questionMessageWrapper {
  padding: 12px 0;
}

.noMessages {
  font-weight: 700;
  font-size: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.actionButtons {
  display: flex;
  align-items: center;
}

.fakePill {
  background: var(--orange);
  padding: 6px 12px;
  color: var(--white);
  border-radius: 200px;
  margin: 12px 0;
  font-weight: bold;
  font-size: 1rem;
}

.fakePillWrapper {
  display: flex;
}

@media (max-width: 450px) {
  .likeGrid {
    grid-template-columns: 1fr;
    grid-template-areas:
      'profile'
      'buttons'
      'messages';
  }

  .infos {
    flex-direction: column;
  }

  .infosText {
    order: 2;
  }

  .imageWrapper {
    order: 1;
    margin-bottom: 12px;
    margin-left: auto;
    margin-right: auto;
  }

  .image {
    width: 270px;
    height: 270px;
    border-radius: 80px;
  }

  .noMessages {
    font-size: 3rem;
  }

  .actionButtons {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .buttonIcon {
    align-items: flex-end;
  }
}
