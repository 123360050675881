.avatarsCard {
  background: var(--input-background);
  border-radius: 20px;
  margin-top: 24px;
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 24px;
}

.avatarImage {
  width: 150px;
  height: 150px;
  border-radius: 1000px;
  background: #f0f0f0;
  object-fit: contain;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.wrapImage {
  composes: image;
  width: 160px;
  height: 160px;
  border-radius: 4000px;
  transition: background 0.2s;
  background: transparent;
}

.selectedImage {
  composes: wrapImage;
  background: red;
}

.contextualActions {
  position: absolute;
  top: 10px;
  right: 10px;
  background: var(--card-background);
  padding: 9px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px var(--box-shadow-color);
  display: flex;
  opacity: 0;
  transition: opacity 0.2s;
}

.image:hover .contextualActions {
  opacity: 0;
}

.deleteCross {
  width: 10px;
  height: 10px;
}
