.loaderCard {
  height: calc(100% - 24px);
}

.editor {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
}

.dogsAvatars {
  grid-column: 1 / 4;
}

.main {
  padding-bottom: 24px;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 24px;
}

.buttonsWrap {
  align-self: flex-end;
  grid-column: 3;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 24px;
  place-content: flex-end;
}

.uploadPicture {
  grid-column: 1 / 4;
}

.inputCardInside {
  display: grid;
  grid-template-columns: auto 1fr;
  padding-top: 24px;
  grid-gap: 24px;
}

.picturesPreview {
  background: var(--white);
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 0 24px;
}

.picturePlaceholder {
  height: 150px;
  width: 150px;
  border: 4px dashed #ddd;
  border-radius: 20px;
  margin-right: 12px;
}

.profilePicture {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
